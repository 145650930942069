@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import 'react-tabulator/lib/styles.css';
@import 'react-tabulator/lib/css/tabulator.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overscroll-behavior: none;
}

body {
  margin: 0;
  font-family: "Noto Sans", Roboto, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.progressSection {
  &:not(:first-of-type) {
    border-left: 3px solid light-dark(var(--mantine-color-white), var(--mantine-color-dark-7));
  }
}